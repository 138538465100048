@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-bg;
  @apply overflow-hidden;
  font-family: 'Poppins', sans-serif;
}

.primaryBgLinear {
  @apply bg-gradient-to-r;
  @apply from-primaryLinearStart;
  @apply via-primaryLinearCenter;
  @apply to-primaryLinearEnd !important;
}

.customScrollHide::-webkit-scrollbar{
  width: 0px;
}

.customScroll::-webkit-scrollbar{
  width: 6px;
}

.customScroll::-webkit-scrollbar-track{
  background-color: none;
}

.customScroll::-webkit-scrollbar-thumb{
  background-color: #e6e6e6e6;
  box-shadow: 1px 1px 7px 1px rgb(255, 255, 255);
  border-radius: 20px;
}

.customScrollLight::-webkit-scrollbar{
  width: 3px;
  height: 6px;
}

.customScrollLight::-webkit-scrollbar-track{
  background-color: none;
}

.customScrollLight::-webkit-scrollbar-thumb{
  background-color: darkgray;
  border-radius: 20px;
}